import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import img1 from "../../logos/photos/IDMIT.jpg"
import MyPDF2 from "../../logos/photos/DOD2024.pdf"





export default function IDIMT() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                PANTHEON takes part in IDIMT Conference
                                 </h3>
                <p>
                TWith more than 30 years of being an international scientific forum, the Interdisciplinary
Information Management Talks (<a href='https://www.google.com/url?sa=D&q=https://idimt.org/&ust=1727953620000000&usg=AOvVaw2EXmE7ExLoVgcZmpdgyYbG&hl=en&source=gmail'>IDIMT Conference</a>) has been providing ample space for the
exchange of concepts and ideas in the areas of software-intensive systems, management and
engineering of information and knowledge, social media, business engineering, and related
topics. This year, the event occurred in Hradec Králové, Czech Republic, between September
4th - 6th, 2024. The conference topic was “Changes to ICT, Management and Business
Processes through AI.”
</p>
<p>
Therefore, it was a great honour for the PANTHEON project to participate in the conference and
present our consortium’s work on “Stakeholder Engagement in Disaster Management:
Participatory Design and- Co-Creation within the PANTHEON Project”. <a href='https://www.johanniter.at/'>The Johanniter Research & Innovation Centre </a>
represented the consortium, and, more specifically, Sarah Kainz
gave the above presentation in a session on Academic Business Cooperation.</p>

<p>
Overall, the conference was an excellent opportunity to share information about the
PANTHEON project and network with participants from various fields, including Informatics,
Engineering, Economics and Business, as well as Security, Health and Food Safety.
</p>
<p>To find the PANTHEON paper, check out pages 394-403.</p>
<a  id='newsletter' href='https://idimt.org/wp-content/uploads/2024/09/IDIMT-2024-proceedings.pdf' >https://idimt.org/wp-content/uploads/2024/09/IDIMT-2024-proceedings.pdf</a>


              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={img1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />

              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
